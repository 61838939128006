import { useEffect, useState } from "react";
import { useGetCurrency } from "../../../Services/useGetCurrency";
import { CashDeskProps } from "../../../Services/useGetCashDesk";
import { Checkbox, CheckboxProps, Col, Form, FormInstance, Row, Select } from "antd";
import { useCashDesk } from "../../../Services/useCashDesk";
import './styles.scss';
import { ResponseAccounts, useAccounts } from "../../../Services/useAccounts";
import { sign } from "crypto";
import { DataType } from "../../../Contexts/UserContext/MovementAcountContext";
import { useGetBranch } from "../../../Services/useGetBranch";

export const FormItemsBuy: React.FC<{
    form_buy_and_sell: FormInstance<any>
    selectedPurchaseCurrency: {
        id: string,
        sign: string,
        name: string
    } | undefined,
    setSelectedPurchaseCurrency: Function,
    customerSelected: {
        id: string,
        name: string
    } | undefined
    balance: ResponseAccounts | undefined
    setBalance: Function,
    rowSelect?: DataType
}> = ({
    form_buy_and_sell,
    selectedPurchaseCurrency, 
    setSelectedPurchaseCurrency,
    customerSelected,
    balance, 
    setBalance,
    rowSelect
}) =>{   

    const { Option } = Select;
    const {data: currencies} = useGetCurrency()
    const {getAccountsCurrencyAndCustomer} = useAccounts()  
    const {getCashCurrency} = useCashDesk()  
    const {data: branches } = useGetBranch()
    const [checkedDeposit, setCheckedDeposit] = useState(true);  
    const [listCahs, setListCash] = useState<CashDeskProps[]>()
    const [spanError, setSpanError] = useState<string>()
    const [branchSelected, setBranchSelected] = useState<number | undefined>()
    const [cashSelected, setCashSelected] = useState<number | null>(null);

    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]   

    useEffect(() => {
        setSpanError(undefined)
        let deposit_cash_value = form_buy_and_sell.getFieldValue('deposit_cash');        
        initialValuesForm() 
        if(rowSelect){
            if(deposit_cash_value && deposit_cash_value.key){
                setCheckedDeposit(true)
            }else{
                setCheckedDeposit(false)
                setListCash(undefined)
            }
            if(rowSelect.destination_checkout_branch_id){
                setBranchSelected(+rowSelect.destination_checkout_branch_id)
            }
            
        }else{
            setCheckedDeposit(true)
            setListCash(undefined)
        }       
    }, []);

    useEffect(()=>{
        if(selectedPurchaseCurrency && customerSelected){
            getBalanceCash()
        }
        
    },[selectedPurchaseCurrency, customerSelected])

    const initialValuesForm = async()=>{        
        if(selectedPurchaseCurrency){
            const data = await getCashCurrency(Number(selectedPurchaseCurrency.id)) 
            if(data){
                let is_not_bank = data.filter((x)=> x.type.id !== 2)
                setListCash(is_not_bank);
                if(data.length == 0){
                    setSpanError(`No existen cajas en ${selectedPurchaseCurrency.sign}. Cree una en Menú -> Administración -> Cajas`)
                }else{
                    if(spanError){
                        setSpanError(undefined)
                    }
                }
            } 
        }        
    }


    const getBalanceCash = async() =>{
        if(selectedPurchaseCurrency && customerSelected){
            const data = await getAccountsCurrencyAndCustomer(Number(customerSelected.id), Number(selectedPurchaseCurrency.id))
            if(data){
                setBalance((prevData: ResponseAccounts | undefined) => {
                    if (JSON.stringify(prevData) !== JSON.stringify(data)) {
                        return data;
                    }
                    return prevData; // No actualizar si son iguales
                });
            }
        }
    }

    const onChangeDeposit: CheckboxProps['onChange'] = async (e) => {
        setCheckedDeposit(e.target.checked);
        form_buy_and_sell.setFieldValue('deposit_cash', undefined); 
    };

    const onChangeCurrency = async(values: {key:string, label: string}, record: any)=>{   
        setSelectedPurchaseCurrency({
            id: values.key,
            sign: record?.sign ? record.sign : '',
            name: values.label
        })

        form_buy_and_sell.setFieldValue('deposit_cash', undefined); 
        const data = await getCashCurrency(Number(values.key)) 
        if(data){
            let is_not_bank = data.filter((x)=> x.type.id !== 2)
            setListCash(is_not_bank);
            if(data.length == 0){
                setSpanError(`No existen cajas en ${ record?.sign ? record.sign : ''}. Cree una en Menú -> Administración -> Cajas`)
            }else{
                if(spanError){
                    setSpanError(undefined)
                }
            }
        }           
    } 


    return(
    <div className="section_buy">       
        <Form.Item
            name='buy_currency'
            className='sell_and_buy_divide'
        > 
            <div className="section_buy_title">Moneda compra</div> 
            <Select                           
                labelInValue  
                value={selectedPurchaseCurrency ? {
                    value: selectedPurchaseCurrency?.name || '', //'Dolar
                    label: selectedPurchaseCurrency?.name || '', //Dolar
                    key: selectedPurchaseCurrency?.id || '', //2
                    sign: selectedPurchaseCurrency?.sign || '' //'USD
                }: undefined}
                loading={!currencies }
                placeholder={!currencies ? 'Cargando monedas...' : 'Moneda'}                       
                style={{minWidth: '105px'}}
                onChange={(values, record)=>{
                    onChangeCurrency(values, record)
                }}
                disabled={(rowSelect?.deposit_operation_approved == 'true' || rowSelect?.extraction_operation_approved == 'true' ) ? true : false}   
            >
                {currencies && currencies && currencies.filter((x)=>x.id !==3).map((item) => (
                    <Option value={item.name} key={item.id} sign={item.sign} >
                        {item.name}
                    </Option>
                ))} 
            </Select>
        </Form.Item>
        <div 
            style={{
                display: 'flex',
                alignContent: 'center'
            }}
        >
            <p style={{padding: '0px', fontWeight: '500', marginRight: '10px'}}>SALDO DE CUENTA CLIENTE:</p>
            <p style={{padding: '0px', fontWeight: '500', color: balance?.balance && balance.balance >= 0 ? 'green' : 'red'}}>
                {balance && selectedPurchaseCurrency && balance.currency_id === Number(selectedPurchaseCurrency.id) ? selectedPurchaseCurrency.sign : ''} {' '}
                {balance? new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balance.balance) : ''}
            </p>
        </div>
        <Form.Item>
            <p style={{margin:'0px', padding: '0p'}}>Depósito en caja:</p>
        </Form.Item>
        {
            checkedDeposit &&
            <Row style={{ display: 'flex', gap: '10px', width: '100%' }}>
                <Col style={{ flex: 1 }}>
                    <Form.Item
                        label='Sucursal'
                        name='branch_cahs'
                        rules={mandatory}
                    >
                        <Select
                            style={{minWidth: 120}}
                            labelInValue  
                            onChange={(e)=>{
                                setBranchSelected(+e.key)
                                setCashSelected(null);
                                form_buy_and_sell.setFieldValue('deposit_cash', undefined)
                            }}
                            placeholder= 'Sucursal'
                        >
                            {branches && branches.map((item) => (
                                <Option value={item.name} key={item.id}>
                                    {item.name}
                                </Option>
                            ))} 
                        </Select> 
                    </Form.Item>
                </Col>
                <Col style={{ flex: 1 }}>
                    <Form.Item
                        label='Caja depósito'
                        name='deposit_cash' 
                        rules={mandatory}
                    >
                        <Select
                            labelInValue
                            style={{minWidth: 120}}
                            placeholder= 'Caja'
                            onChange={(e)=>setCashSelected(+e.key)}
                            value={cashSelected ? { 
                                key: cashSelected.toString(), 
                                label: listCahs?.find((item)=>item.id == cashSelected)?.name, 
                                value: listCahs?.find((item)=>item.id == cashSelected)?.name 
                            } : null}  // Mostrar el valor seleccionado
                            disabled={rowSelect?.deposit_operation_approved == 'true'  ? true : false}  
                        >
                            {listCahs && listCahs
                                .filter((item) => item.branch.id === branchSelected)
                                .map((item) => (
                                <Option value={item.name} key={item.id}>
                                    {item.name}
                                </Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>                
            </Row>           
        }     
       {spanError && <span style={{color: 'red'}}>{spanError}</span>}              
    </div>)
}