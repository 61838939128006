import React, { useContext, useEffect, useState } from "react"
import Table, { ColumnsType } from "antd/es/table"
import { StylesContext } from "../../../Contexts/UserContext/StylesContext"
import './styles.scss'
import { Button, Col, Form, Modal, Row, Select, Tooltip } from "antd"
import {
    ShopOutlined,
    ExclamationCircleOutlined,
    DeleteOutlined,
    CloseOutlined,
    UndoOutlined,
  } from '@ant-design/icons';
import { useForm } from "antd/es/form/Form"
import { useGetBranch } from "../../../Services/useGetBranch"
import { useGetCheckoutGetAll } from "../../../Services/useGetCheckouts"
import { useOperations } from "../../../Services/useOperations"
import { Transaction } from "../../../Services/useGetMovements"
import { DataType_AllOperation } from "./types"
import moment from "moment"
import { RenderOperationType } from "../../RenderOperationType"
import { DataType } from "../../../Contexts/UserContext/MovementAcountContext"
import { RenderApproved } from "../../RenderApproved"
import { VariablesContext } from "../../../Contexts/UserContext/VariablesContext"
import USDT from "../../Forms/USDT"
import Windows from "../../Forms/Windows"
import DepositExtractionForm from "../../Forms/DepositExtractionForm"
import PucrchaseSaleCurrencyForm from "../../Forms/PucrchaseSaleCurrencyForm"
import TransfersBetweenBoxes from "../../Forms/TransfersBetweenBoxes"
import { OperationsContext } from "../../../Contexts/UserContext/OperationsContext"
import { ValuesForm } from "../OperationSections/types"

const formatNumber = (value: any) => {
    if (value === undefined || value === null || isNaN(value)) {
        return value; // Retorna el valor sin formatear si no es un número
    }
    return new Intl.NumberFormat('es-ES').format(Number(value));
};


const RenderCash = React.memo(({ debitCash, creditCash, record }: 
    { 
        debitCash: string | undefined | number; 
        creditCash: string | undefined | number 
        record: DataType_AllOperation
    }
) => {
    let isGray = record.is_deleting_operation == 'true' || record.deleted == 'true' ? 'rgb(114, 114, 114)' : undefined
    if (debitCash && creditCash) {
        return <span style={{ color: isGray ? isGray : 'red' }}>D</span>;
    }

    if (debitCash) {
        return <span style={{ color: isGray ? isGray : 'orange' }}>- {record.origin_checkout_currency_sign} {formatNumber(debitCash)}</span>;
    }

    if (creditCash) {
        return <span style={{ color: isGray ? isGray : '#30cb85' }}>{record.destination_checkout_currency_sign} {formatNumber(creditCash)}</span>;
    }

    return <span></span>;
});

const RenderNumOperations = React.memo((value: {text: any, record: DataType})=>{
  return(
      <Row
          justify={
            value.record.deleted === 'true' || value.record.is_deleting_operation === 'true'
              ? 'space-between'
              : 'start'
          }
      >
          <span className="N_hover">{value.text}</span>            
          {value.record.deleted === 'true' && 
              <Tooltip
                  title='Eliminado'
              >
                  <CloseOutlined style={{color: 'red'}}/>
              </Tooltip>                
          }
           {value.record.is_deleting_operation === 'true' && 
              <Tooltip
                  title='Nota de crédito'
              >
                  <UndoOutlined style={{color: 'red'}}/>  
              </Tooltip>                
          }
      </Row>
      
  )
})

const RenderCustomer = React.memo((value: {text: any, record: DataType}) => {
    let colorTxt='#ADADAD'
    let customer = value.text

    if(value.record.full_name){
        colorTxt = 'yellow'
    }
    if(value.record.deleted === 'true' || value.record.is_deleting_operation === 'true'){
        colorTxt = 'rgb(114, 114, 114)'
    }    
    if(value.record.customer_id === '0'){
        customer = ''
    }
    if(value.record.full_name){
        customer = value.record.full_name
    }
    return(
        <span
            style={{
                display: 'inline-block',
                width: '100%', 
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color:colorTxt ,
            }}
            title={value.record.full_name ? '(Ventanilla): ' + customer  : customer} 
        >
            {customer}
        </span>
    )
})

export const AllCheckout = () =>{

    const {getCashMovementsByBranch} = useOperations()
    const {data: branches} = useGetBranch()
    const {data: checkouts} = useGetCheckoutGetAll()
    
    const { setShowForms, showForms } = useContext(VariablesContext)
    const { deleteOperation } = useOperations()
    const {Option} = Select;     
    const [form_checkouts] = useForm()

      const {
        dataTableAllCashes, 
        setDataTableAllCashes,
        mutateAllCheckout, 
        setMutateAllCheckout, 
        columns,
        setColumns,        
        branchSelected, 
        setBranchSelected,
        keyNumberDeleted, 
        setKeyNumberDeleted,
        showConfirmDeleteAllCheckout: showConfirmDelete, 
        setShowConfirmDeleteAllCheckout: setShowConfirmDelete,
        rowSelect_allCheckout: rowSelect,
        setRowSelect_allCheckout: setRowSelect,
      } = useContext(OperationsContext)
       
    const [dataApi, setDataApi] = useState<Transaction[]>([]) 
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
     const [adjustedTableHeigthScroll, setAdjustedTableHeigthScroll] = useState<number>(window.innerHeight > 900 ? window.innerHeight *0.67 : window.innerHeight *0.64);
    
     useEffect(() => {
           console.log(window.innerHeight)
           const handleResize = () => {
             const screenHeight= window.innerHeight > 900 ? window.innerHeight *0.65 : window.innerHeight *0.64;
             
             setAdjustedTableHeigthScroll(screenHeight);
            
           };
       
           handleResize();
           window.addEventListener('resize', handleResize);
       
           return () => {
             window.removeEventListener('resize', handleResize);
           };
         }, []);
    const [loadingBtn, setLoadingBtn] = useState(false)

    const [form_buy_and_sell] = useForm()
    const [transfers_between_cashs] = useForm()
    const [form_deposit_extraction] = useForm()

     useEffect(()=>{
       if(mutateAllCheckout && branchSelected && branchSelected.id){
            handleChangeBranch(false)  
            setMutateAllCheckout(false)
       }else{
        setMutateAllCheckout(false)
       }       
    },[mutateAllCheckout])


    const filters = (
        type:any,
        dataApi: Transaction[]
    ) => {
        let textAndValue: { text: string, value: string }[] = [];
        let uniqueValues = new Set();

        dataApi?.forEach((item) => {
            let code = ''
            let value = ''
            switch(type){
                case 'n°':
                    code = item.operation_id?.toString()
                    value =  item.operation_id?.toString()
                    break;
                case 'operation_type':
                    code = item.type.name;
                    value = item.type.id.toString();
                    break;
                case 'approved':
                    code = item.approved ? 'Aprobado' : 'No aprobado';
                    value = item.approved ? 'true' : 'false';
                    break; 
                   
                case 'customer_name':
                    const firstName = item.destination_account?.customer?.first_name;
                    const lastName = item.destination_account?.customer?.last_name;
                    let txt: string | undefined

                    if((firstName && lastName) && item.destination_account.customer.id !== 0){
                        txt = firstName + ' ' + lastName                        
                    }
                    if(item.full_name){ 
                        txt = item.full_name                        
                    }                
                    if (txt) {
                        code = txt;
                        value = txt;
                    }
                    break;   
                case 'create_date':
                    code = moment(item.created_at).format('DD-MM-YYYY')
                    value = moment(item.created_at).format('DD-MM-YYYY')
                    break;   
                case 'approval_date':
                    code = moment(item.updated_at).format('DD-MM-YYYY')
                    value = moment(item.updated_at).format('DD-MM-YYYY')
                    break;                 
                default:
                    code ='';
                    value = ''
                    break               
            }
            
            if (code && !uniqueValues.has(code)) {
                uniqueValues.add(code);
                textAndValue.push({
                    text: code,
                    value: value
                });
            }

        });

        textAndValue.sort((a, b) => {
            if (!isNaN(Number(a.text)) && !isNaN(Number(b.text))) {
                return Number(a.text) - Number(b.text); // Para números
            } else {
                return a.text.localeCompare(b.text); // Para strings
            }
        });
        return textAndValue;
    } 

    const columnsDefault: ColumnsType<DataType_AllOperation> = [
        {
            title: 'N°',
            dataIndex: 'operation_id',
            render: (text: any, record: DataType_AllOperation)=><RenderNumOperations text={text} record={record}/>,
            width: 80,
            fixed: 'left' as const, // Asegurar que 'left' sea compatible con el tipo FixedType
            onFilter: (value, record) => typeof value === 'string' && record.approved == value,
            filterSearch: true, 
        },
        {
            title: 'OA',
            dataIndex: 'associated_operation',
            width: 70,
        },       
        {
            title: 'Fecha creación',
            dataIndex: 'create_date',
            className: 'text-center',
            width: 120,
            onFilter: (value, record) => typeof value === 'string' && record.create_date == value,
            filterSearch: true, 
        },
        {
            title: 'Fecha aprobación',
            dataIndex: 'approved_date',
            className: 'text-center',
            width: 130,
            onFilter: (value, record) => typeof value === 'string' && record.approval_date == value,
            filterSearch: true, 
        },
        {
            title: 'Cliente',
            dataIndex: 'customer_name',
            render: (text, record) => <RenderCustomer text={text} record={record}/>,
            onFilter: (value, record) => typeof value === 'string' && 
            record.customer_name == value || record.full_name == value,
            filterSearch: true, 
            width: 170,
        },
        {
            title: 'Aprobado',
            dataIndex: 'approved',
            render: (text: string, record: DataType)=> 
                <RenderApproved 
                    text={text} 
                    record={record} 
                    setDataTable={setDataTableAllCashes} 
                    dataTable={dataTableAllCashes} 
                    callback ={handleChangeBranch}
                />,
            width: 100,
            className:'cursor-pointer',             
            onFilter: (value, record) => typeof value === 'string' && record.approved == value,    
              
        },     
        {
            title: 'Operación',
            dataIndex: 'operation_type',
            render: (text, record)=>{return<RenderOperationType text={text} record={record}/>},
            onFilter: (value, record) => typeof value === 'string' && record.operation_type_id == value, 
            width: 200,
        },
        {
            title: 'Tipo de cambio',
            dataIndex: 'currency_quote',
            className: 'text-center',
            width: 120,
        },        
        {
            title: 'TC USDT',
            dataIndex: 'porcentage_currency_quote',
            className: 'text-center',
            width: 110,
        },
    ];  

    
    const handleChangeBranch = async(loading: boolean)=>{
        let branch_id: number = 0
        let formData:{
                operation_type: ValuesForm        
              } = form_checkouts.getFieldsValue()
        if(formData && formData.operation_type && formData.operation_type.key ){
            branch_id = +formData.operation_type.key
            let select = branches.find((x)=>x.id === +formData.operation_type.key)            
            setBranchSelected(select)
        }else if(branchSelected?.id){
            branch_id = branchSelected?.id
        }
        
        if(branch_id !== 0){
            const data = await getCashMovementsByBranch(callback, branch_id)
            if(data){                
                addColumns(branch_id, data)
                rowData(data)
                setDataApi(data)
            } 
        }               
    }

    const handleRenderer = (text: string, record: DataType) =>{
        return (
          <div className='currency_action_action_render'>
            {
              record.deleted == 'true' || 
              record.is_deleting_operation == 'true' ||
              ( record.full_name && record.operation_type_id !== '7') ||
               record.related_operation_id 
              ? <div></div>
              : <DeleteOutlined 
                onClick={()=>{
                    setRowSelect(record)
                    setShowConfirmDelete(true)
                }}
                style={{color: '#37bde0'}} />           
            }            
          </div>  
        )      
      }
    

    const addColumns = (id_branch: number, dataApi: Transaction[] ) => {
        console.log(keyNumberDeleted)
        if (checkouts) {
            let columnsWithDelete: ColumnsType<DataType_AllOperation> = []
            let lastKeyValue: number = 0
            let newColumns: ColumnsType<DataType_AllOperation> = []
            let branch = branches.find((x)=> x.id == id_branch)?.name
            const listCheckout = checkouts.filter((item) =>{ 
                if(item.branch.id === id_branch){return item}
            });            
  
            const columnsWithKeys = columnsDefault.map((column, index) => {
                lastKeyValue = index + 1;
            
                // Type guard: verificamos si 'column' tiene 'dataIndex'
                if ('dataIndex' in column && column.dataIndex && column.dataIndex !== 'currency_quote' && column.dataIndex !== 'associated_operation') {
                    return {
                        ...column,
                        key:(index + 1).toString(), // Agregamos un prefijo al key
                        filters: filters(column.dataIndex, dataApi) || [], // Nos aseguramos de devolver un array válido
                    };
                }
            
                // Si no tiene 'dataIndex', simplemente retornamos la columna original con un 'key'
                return {
                    ...column,
                    key: (index + 1).toString(),
                };
            });


            if(lastKeyValue >0){
                newColumns= listCheckout.map((checkout, index) =>{ 
                    lastKeyValue = lastKeyValue +1
                    return({
                        title: checkout.name,
                        dataIndex: `cash_${checkout.id}`, // Clave única para esta columna
                        key: lastKeyValue.toString(),
                        className: index % 2 === 0 ? 'background_color_cell_checkout text-right':'text-right',
                        render: (_: any, record: DataType_AllOperation) => {
                            const debitCash = record[`debit_cash_${checkout.id}`];
                            const creditCash = record[`credit_cash_${checkout.id}`];
                            return <RenderCash debitCash={debitCash} creditCash={creditCash} record={record}/>;
                        },
                        width: 120,                
                })});
            }
            
    
            if(newColumns.length >0  && lastKeyValue > 0){
                lastKeyValue = lastKeyValue + 1
                columnsWithDelete = [
                    ...columnsWithKeys,
                    {
                        title: 'Sucursal ' + branch,
                        children: newColumns,
                    }, 
                    {
                        title: 'Eliminar',
                        dataIndex: 'id',
                        key: lastKeyValue,
                        render: handleRenderer,
                        className: 'text-center',
                        width: 100,
                    },                                                               
                ];
                setColumns(columnsWithDelete);
                setKeyNumberDeleted(lastKeyValue)
            }    
            console.log(columnsWithDelete)              
        }
    };

    const showCreditOrDebit = (operation_type: number, transaction: Transaction, type: 'credit' | 'debit' )=>{
        return type == 'credit' ? transaction.credit?.toString() : transaction.debit?.toString()
    }          

    const rowData = (data: Transaction[]) =>{
        let list: DataType_AllOperation[] = []
       
        data.map((transaction, index)=>{
            let  cash_credit_id = transaction.destination_checkout?.id || ''
            let  cash_debit_id = transaction.origin_checkout?.id || ''
            let operation_type = transaction.type.id
            let uploadingData = true

            // if(operation_type === 7 && !transaction.windows_operation && transaction.approved){
            //     uploadingData = false
            // }
            // if(operation_type === 9 && transaction.approved){
            //     uploadingData = false
            // }
            // if(!transaction.approved){
            //     uploadingData = false
            // }
            
            list.push({            
                key: index.toString(),
                associated_operation: transaction.related_operation_user_id?.toString() || undefined,
                create_date: moment(transaction.created_at).format('DD-MM-YYYY') || moment(transaction.created_at).format('DD-MM-YYYY  HH:mm:ss'),
                approval_date: moment(transaction.updated_at).format('DD-MM-YYYY') || moment(transaction.created_at).format('DD-MM-YYYY  HH:mm:ss'),
                date_aproved: '',
                customer_name: transaction.customer?.first_name + ' ' + transaction.customer?.last_name || '',
                balance_destination_checkout: transaction.destination_checkout_balance?.toString() || '',
                balance_origin_checkout: transaction.origin_checkout_balance?.toString() || '',
                destination_account_balance: transaction.destination_account_balance?.toString() || '',
                destination_checkout_balance: transaction.destination_checkout?.balance?.toString() || '',
                origin_account_balance: transaction.origin_account_balance?.toString() || '',
                origin_checkout_balance: transaction.origin_checkout?.balance?.toString() || '',                    
                approved: transaction.approved ? 'true' : 'false',
                balance: undefined,
                can_be_approved: transaction.can_be_approved ? 'true' : ' false',
                commission: transaction.commission?.toString() || undefined,
                credit: transaction.credit?.toString() || '',
                credit_number: transaction.credit?.toString() || '',
                currency_quote_buy_price: transaction.currency_quote_buy_price?.toString() || undefined,
                currency_quote_sell_price: transaction.currency_quote_sell_price?.toString() || undefined,
                currency_sale: transaction.currency_sale ? 'true' : 'false',
                customer_first_name: transaction.customer?.first_name || '',
                customer_id: transaction.customer?.id?.toString() || '',
                customer_last_name: transaction.customer?.last_name || '',                
                date:'',
                debit: transaction.debit?.toString() || '',
                debit_number: transaction.debit?.toString() || '',
                description: transaction.description || '',
                deleted: transaction.deleted ? 'true' : 'false',
                deposit_operation_approved: transaction.deposit_operation_approved ? 'true' : ' false',
                destination_account_currency_id: transaction.destination_account?.currency?.id?.toString() || '',
                destination_account_currency_name: transaction.destination_account?.currency?.name || '',
                destination_account_currency_sign: transaction.destination_account?.currency?.sign || '',
                destination_account_customer_id: transaction.destination_account?.customer?.id?.toString() || '',
                destination_account_customer_last_name: transaction.destination_account?.customer?.last_name || '',
                destination_account_customer_name: transaction.destination_account?.customer?.first_name || '',
                destination_account_descriptrion: '',
                destination_balance: '',
                destination_checkout_currency_id: transaction.destination_checkout?.currency.id?.toString() || '',
                destination_checkout_currency_name: transaction.destination_checkout?.currency?.name || '',
                destination_checkout_currency_sign: transaction.destination_checkout?.currency?.sign || '',
                destination_checkout_id: transaction.destination_checkout?.id?.toString() || '',
                destination_checkout_name: transaction.destination_checkout?.name || '',
                destination_checkout_sign: transaction.destination_checkout?.currency?.sign || undefined,
                destination_checkout_branch: transaction.destination_checkout?.branch?.name || undefined,
                destination_checkout_branch_id: transaction.destination_checkout?.branch?.id?.toString() || undefined,
                destination_account_id: undefined,
                extraction_operation_approved: transaction.extraction_operation_approved ? 'true' : ' false',
                full_name: transaction.full_name || undefined,
                id: transaction.id?.toString(),
                is_deleting_operation: transaction.is_deleting_operation ? 'true' : 'false',
                is_sale: transaction.currency_sale === true ? 'true' : transaction.currency_sale === false ? 'false' : undefined,
                operation_id: transaction.operation_id?.toString() || undefined,
                operation_type_id: transaction.type.id?.toString() || undefined,
                operation_type_name: transaction.type.name || undefined,            
                origin_account_id: transaction.origin_account?.id?.toString() || '',
                origin_account_currency_id: transaction.origin_account?.currency?.id?.toString() || '',
                origin_account_currency_name: transaction.origin_account?.currency?.name || '',
                origin_account_currency_sign: transaction.origin_account?.currency?.sign || '',
                origin_account_customer_id: transaction.origin_account?.customer?.id?.toString() || '',
                origin_account_customer_last_name: transaction.origin_account?.customer?.last_name || '',
                origin_account_customer_name: transaction.origin_account?.customer?.first_name || '',
                origin_account_descriptrion: '',
                origin_balance: '',
                origin_checkout_currency_id: transaction.origin_checkout?.currency?.id?.toString() || '',
                origin_checkout_currency_name: transaction.origin_checkout?.currency?.name || '',
                origin_checkout_currency_sign: transaction.origin_checkout?.currency?.sign || '',
                origin_checkout_id: transaction.origin_checkout?.id?.toString() || '',
                origin_checkout_name: transaction.origin_checkout?.name || '',                    
                origin_checkout_branch: transaction.origin_checkout?.branch?.name || undefined,
                origin_checkout_branch_id: transaction.origin_checkout?.branch.id?.toString() || undefined,
                related_operation_id: transaction.related_operation_id?.toString() || undefined,
                secondary_currency_id: transaction.secondary_currency?.id?.toString() || undefined,
                secondary_currency_name: transaction.secondary_currency?.name || undefined,
                secondary_currency_sign: transaction.secondary_currency?.sign || undefined,
                supplier_commission: transaction.supplier_commission || undefined,
                usdt_sale: transaction.usdt_sale === true ? 'true' : transaction.usdt_sale === false ? 'false' : undefined,
                usdt_quote_percentage: transaction.usdt_quote_percentage?.toString() || undefined,
                user_id: transaction.user?.id?.toString() || undefined,
                user_last_name: transaction.user?.first_name || undefined,
                user_name: transaction.user?.last_name || undefined,
                windows_operation:  transaction.windows_operation === true ? 'true' : transaction.windows_operation === false ? 'false' : undefined,
                approved_date: transaction.approved_date ? moment(transaction.approved_date).format('DD-MM-YYYY') : undefined,

                
                    
                [`credit_cash_${cash_credit_id}`]: showCreditOrDebit(operation_type, transaction, 'credit'),
                [`debit_cash_${cash_debit_id}`]: showCreditOrDebit(operation_type, transaction, 'debit'),
                currency_quote: transaction.currency_quote_buy_price?.toString() || 
                    transaction.currency_quote_sell_price?.toString() ||
                    undefined,
                porcentage_currency_quote: transaction.usdt_quote_percentage ? transaction.usdt_quote_percentage + '%' : undefined
            })
            
          
        })
        setDataTableAllCashes(list)
    }

    const callback = ()=>{

    }

    const onClickRow = (record: DataType) =>{         
        if(record &&  record.approved == 'false'){
            setRowSelect(record);
        }  
    }  

    const callbackEliminate = () =>{
        setLoadingTableData(false)
        setRowSelect(undefined)        
        setShowConfirmDelete(false)
    }
      
    const ModalConfirmDelete = React.memo( () => {
    return(
        <Modal
            className='movements_section_icon'
            title="Eliminar"
            open={showConfirmDelete}
            onOk={async()=>{
                if(rowSelect && rowSelect.id){
                    const data = await deleteOperation(callbackEliminate, +rowSelect.id)
                    if(data){
                        handleChangeBranch(false)
                    }
                }}}
            onCancel={()=>setShowConfirmDelete(false)}
            okText="Eliminar"
            cancelText="Cancelar"
        >
            <p>¿Estás seguro de que desea eliminar operación N° {rowSelect?.operation_id} ?</p>
        </Modal>
    )
    })

    return(
        <>    <ModalConfirmDelete/>
              { showForms.usdt &&
                <USDT
                  form_buy_and_sell={form_buy_and_sell}
                  rowSelect={rowSelect}
                  setRowSelect={setRowSelect}
                  checkAmount={true}
                />
              } 
              { showForms.windows &&
                <Windows
                  form_buy_and_sell={form_buy_and_sell}
                  rowSelect={rowSelect}
                  setRowSelect={setRowSelect}
                />
              } 
              { showForms.deposit_extraction &&
                <DepositExtractionForm
                  form={form_deposit_extraction}
                  rowSelect={rowSelect}
                  setRowSelect={setRowSelect}
                />
              } 
              { showForms.buy_and_sell &&
                <PucrchaseSaleCurrencyForm
                  form_buy_and_sell={form_buy_and_sell}
                  rowSelect={rowSelect}
                  setRowSelect={setRowSelect}                  
                  checkAmount={true}
                />
              }   
              { showForms.transfers_between_cashs && 
                <TransfersBetweenBoxes
                  form={transfers_between_cashs}
                  rowSelect={rowSelect}
                  setRowSelect={setRowSelect}
                />
              }  
            <div className="allCheckout_section allCheckout_section_container hover_row">
                <Row
                    className='global_title_section'  
                >
                    <Col
                    style={{
                        display: 'flex',
                        alignItems: ' center',
                    }}
                    >
                    <h3> <ShopOutlined style={{fontSize: 18, marginRight: '10px', color: '#37bde0'}}/>INGRESO Y EGRESO CAJAS</h3> 
                    </Col>
                </Row>   
                <Row 
                    className='global_button_box'
                    style={{
                    display:'flex',
                    justifyContent: 'space-between',
                    alignItems:'center'
                    }}
                >              
                <Col >
                <Form
                    layout='inline'
                    onFinish={()=>handleChangeBranch(false)}
                    id='form_operations_filters' 
                    form={form_checkouts}
                >
                    <Form.Item
                        name='operation_type'
                        initialValue={ branchSelected ?{
                            labe:branchSelected?.name,
                            key:branchSelected?.id,
                            value: branchSelected?.name
                        }: undefined}
                    >
                        <Select                          
                            labelInValue  
                            loading={!branches }
                            placeholder='Sucursal'
                            style={{minWidth: '130px'}}    
                            showSearch                                              
                        >
                            {branches && branches.map((item) => (
                                <Option value={item.name} key={item.id} >
                                    {item.name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item> 
                    <Form.Item>
                        <Button
                            type='primary'
                            className='allCheckout_section_btn'
                            htmlType='submit'
                            loading={loadingBtn}
                        >
                            Buscar
                        </Button>
                    </Form.Item>                                                                      
                    <Tooltip
                        title= 'Seleccione una sucursal para ver los movimientos de todas sus cajas.'
                        className='cursor-pointer'
                    >
                        <ExclamationCircleOutlined style={{color: 'orange'}}/>
                    </Tooltip>
                </Form>                               
                </Col>  
                            
                </Row>
                <Row 
                    style={{
                        display:'flex',
                        justifyContent: 'center',
                        width: '100%',
                        height: '85%',
                        padding: '0px 30px',                        
                        border: '1px solid   #645fca6c',
                }}
                >
                    <Table 
                        style={{
                            width: '100%',
                            marginTop: '1%',
                        }}                    
                        columns={columns} 
                        dataSource={dataTableAllCashes}  
                        scroll={{ y: adjustedTableHeigthScroll, x: 800 }}                                   
                        pagination={{
                            pageSize: 50,
                            showSizeChanger: false,                                      
                        }}
                        rowClassName={(record)=>{  return record.deleted === 'true' || record.is_deleting_operation === 'true' ? 'cell_deleted cursor-pointer ' : 'cursor-pointer'}}
                        onRow={(record, ) => ({
                            onClick: (event) => {
                                console.log(keyNumberDeleted)
                              // Verifica condiciones principales y selecciona celdas de "Aprobado" y "Eliminar" una sola vez
                              const isApprovable = record.approved === 'false' && record.can_be_approved === 'true' && record.deleted === 'false' && record.is_deleting_operation === 'false';
                              const noRelatedOperation = !record.related_operation_id || record.related_operation_id === 'false';
                              
                              if (isApprovable && noRelatedOperation) {
                                const approvedCell = event.currentTarget.querySelector('td:nth-child(6)');
                                const deleteCell = event.currentTarget.querySelector(`td:nth-child(${keyNumberDeleted}`); 
                            
                                // Verifica si el clic ocurrió en las celdas "Aprobado" o "Eliminar"
                                if ((approvedCell && approvedCell.contains(event.target)) || (deleteCell && deleteCell.contains(event.target))) {
                                  return;
                                }
                            
                                // Llamada al manejador de clic
                                onClickRow(record);
                            
                                // Variables comunes para los estados de formulario
                                const isAccountValid = record.origin_account_customer_id !== '0' && record.destination_account_customer_id !== '0';
                                const isRelated = isAccountValid && !record.related_operation_id;
                                let formStates
                                // Define el estado del formulario
                                if(record.operation_type_id){
                                  formStates = {
                                    deposit_extraction: ['1', '2', '3', '4'].includes(record.operation_type_id) && isRelated,
                                    transfers_between_cashs: record.operation_type_id === '5' && isAccountValid,
                                    transferer: record.operation_type_id === '6' && isAccountValid,
                                    buy_and_sell: record.operation_type_id === '7' && isRelated && record.origin_account_customer_id !== '0',
                                    windows: record.operation_type_id === '7' && record.origin_account_customer_id === '0',
                                    usdt: record.operation_type_id === '9' && isAccountValid,
                                    transferer_to_suppliers: ['10', '11'].includes(record.operation_type_id) && isAccountValid, 
                                  };
                                }                                                        
                                setShowForms(formStates);
                              }
                            }
                          })}                
                    /> 
                </Row>
            </div>

        </>
    )
}