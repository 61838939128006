import React, { useContext, useEffect, useState } from "react";
import './styles.scss';
import { Checkbox, CheckboxProps, Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { TransfersBetweenBoxesProps } from "./types";
import { CashDeskProps, useGetCashDesk } from "../../../Services/useGetCashDesk";
import { CheckoutBalance, useCashDesk } from "../../../Services/useCashDesk";
import { useOperations } from "../../../Services/useOperations";
import { VariablesContext } from "../../../Contexts/UserContext/VariablesContext";
import { UserContext } from "../../../Contexts/UserContext/UsersContext";
import { useGetBranch } from "../../../Services/useGetBranch";

export interface FilterValues{
    value: string,
    label: string,
    key: number,
}

export interface CashValuesForm {
    origin_checkout: FilterValues,
    destination_checkout: FilterValues,
    amount: number,
    description: string
}

export const TransfersBetweenBoxes: React.FC<TransfersBetweenBoxesProps> =({
    form,  
    rowSelect,
    setRowSelect 
})=>{
    const {data: cashs} = useGetCashDesk()
    const {userData} = useContext(UserContext)
    const { Option } = Select;
    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]
    const {getCheckoutBalance, getCashCurrency} = useCashDesk()
    const {createOperation, editOperation} = useOperations()
    const {collapsedFilsters, setCollapsedFilters, setShowForms, showForms} = useContext(VariablesContext)
    const {data: branches } = useGetBranch()

    const [spanError, setSpanError] = useState<string>()
    const [loadingDestiantioinAccount, setLoadingDestinationAccount] = useState(false)   
    const [cash, setCash] = useState<{id: number, name: string, currency:{id: number, sign: number}} | undefined>()
    const [balance, setBalance] = useState<CheckoutBalance>() 
    const [listDestinationCheckout, setListDestinationAccount] = useState<CashDeskProps[]>([])    
    const [approved, setaproved] = useState(false)    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [disabledBtn, setDisabledBtn] = useState(false)
    const [originBranch, setOriginBranch] = useState<number>()
    const [destinationBranch, setDestinationBranch] = useState<number>()

    useEffect(()=>{
        form.resetFields()
        if(rowSelect && rowSelect.destination_checkout_branch_id && rowSelect.origin_checkout_branch_id){
            setDestinationBranch(+rowSelect.destination_checkout_branch_id)
            setOriginBranch(+rowSelect.destination_checkout_branch_id)
        }
    },[])

    const handleCancel =()=>{
        setShowForms(Object.fromEntries(Object.keys(showForms).map(key => [key, false])))// pone todos en false
        if(setRowSelect){
            setRowSelect(undefined)
        }
    }

    const handleSave = async (e: CashValuesForm)=>{
       
        let operationData
        if(userData){
            operationData = {
                type_id: 5, //correspondiente a Transferencia entre cajas, por eso va harcord
                origin_checkout_id: e.origin_checkout.key,
                destination_checkout_id: e.destination_checkout.key,
                debit: e.amount,
                user_id: userData.id,
                approved: approved,
                description: e.description 
            }   
        }             

        if(operationData){
            let newData
            if(rowSelect){  
                newData = await editOperation(handleCancel, rowSelect.id!,  operationData)
            
            }else{
                newData = await createOperation(handleCancel, operationData)
            }
            if(newData){
                //chequeo si la tabla está cargada y si la operacion creada coincide con los datos un tabla para agregarlo
                // if (showingCash && ( showingCash.id == newData.destination_checkout.id || showingCash.id == newData.origin_checkout.id)){
                //    setMutate(true)
                // }
                // mutateOperationsSections(true)
            }           
            setCollapsedFilters(!collapsedFilsters)  
        }  
    }           

    const changeOriginCheckout = async(e: FilterValues,  values: any) =>{
        form.setFieldsValue({ amount: undefined, destination_checkout: undefined });
        setSpanError(undefined)
        if(e){
            let cash = e.key
            const balanceData = await getCheckoutBalance(cash) //consulto el balance de caja
            setCash({
                id: e.key, 
                name: e.label, 
                currency:{
                    id: values.currency.id, 
                    sign: values.currency.sign
                }
            })
            if(balanceData && balanceData){
                setBalance(balanceData)
            }    
            setLoadingDestinationAccount(true)
            const accountsData = await getCashCurrency(values.currency.id) //endpoint para cargar segundo selector según moneda de origen
            if(accountsData){
                let list = accountsData.filter((x)=> x.id != cash  )
                setListDestinationAccount(list)
            }                    
        }          
    }

    const onChangeAmount = (e: any) =>{
        if(balance){ 
            if(e > balance.balance ){            
                let value = new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balance.balance)
                setSpanError('El monto a transferir supera el monto de cuenta: ' + cash?.currency.sign + ' ' + value  )  
                setDisabledBtn(true)
            }else{
                setSpanError(undefined)
                setDisabledBtn(false)
            }
        }
    }  
    
    const onChangeapproved: CheckboxProps['onChange'] = async (e) => {
        setaproved(e.target.checked); 
    };

    const convertToNumber = (value: string) => {
        if (typeof value === 'string') {
          return parseFloat(value.replace(/\./g, '').replace(',', '.'));
        }
        return value;
      };

    return(
        <div>
            <Modal
                title= 'Transferencia entre cajas'
                open={showForms.transfers_between_cashs}
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form.submit()}
                okButtonProps={{
                    disabled: disabledBtn
                }}
                
            >
                <Form
                    form={form}
                    id='form_deposit_extraction'
                    onFinish={handleSave}
                    layout="vertical"                   
                >    
                    <Row>
                        <Col style={{ flex: 1 }}>
                                <Form.Item
                                    label='Sucursal'
                                    name='branch_origin'
                                    rules={mandatory}
                                    initialValue={rowSelect ?{
                                        value: rowSelect.origin_checkout_branch,
                                        label: rowSelect.origin_checkout_branch,
                                        key: +rowSelect.origin_checkout_branch_id!,
                                    }: undefined}
                                >
                                    <Select
                                        labelInValue  
                                        onChange={(e)=>{
                                            setOriginBranch(+e.key)
                                            form.setFieldValue('origin_checkout', undefined)
                                            form.setFieldsValue({ amount: undefined, destination_checkout: undefined });
                                        }}
                                        placeholder= 'Sucursal'
                                    >
                                        {branches && branches.map((item) => (
                                            <Option value={item.name} key={item.id}>
                                                {item.name}
                                            </Option>
                                        ))} 
                                    </Select> 
                                </Form.Item>
                            </Col>
                            <Col  style={{ flex: 1 }}>
                                <Form.Item
                                    label='Caja de origen'
                                    name='origin_checkout'
                                    rules={mandatory}
                                    initialValue={rowSelect ?{
                                        value: rowSelect.origin_checkout_name,
                                        label: rowSelect.origin_checkout_name,
                                        key: +rowSelect.origin_checkout_id!,
                                    }: undefined}
                                >
                                    <Select                           
                                        labelInValue  
                                        loading={!cashs }
                                        placeholder={!cashs ? 'Cargando cajas...' : 'Seleccione una caja'}      
                                        style={{minWidth: '200px'}} 
                                        onChange={changeOriginCheckout}                                                          
                                    >
                                        {cashs && cashs
                                            .filter((item) => item.branch.id === originBranch)
                                            .map((item) => (
                                                <Option value={item.name} key={item.id} currency={item.currency}>
                                                    {item.name}
                                                </Option>
                                            ))
                                        } 
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                      
                    <Row>
                        <Col style={{ flex: 1 }}>
                            <Form.Item
                                label='Sucursal'
                                name='branch_destination'
                                rules={mandatory}
                                initialValue={rowSelect ?{
                                    value: rowSelect.destination_checkout_branch,
                                    label: rowSelect.destination_checkout_branch,
                                    key: +rowSelect.destination_checkout_branch_id!,
                                }: undefined}
                            >
                                <Select
                                    labelInValue  
                                    onChange={(e)=>{
                                        setDestinationBranch(+e.key)
                                        form.setFieldValue('destination_checkout', undefined)
                                    }}
                                    placeholder= 'Sucursal'
                                >
                                    {branches && branches.map((item) => (
                                        <Option value={item.name} key={item.id}>
                                            {item.name}
                                        </Option>
                                    ))} 
                                </Select> 
                            </Form.Item>
                        </Col>
                        <Col style={{ flex: 1 }}>
                            <Form.Item
                                label='Caja de destino'
                                name='destination_checkout'
                                rules={mandatory}
                                initialValue={rowSelect ?{
                                    value: rowSelect.destination_checkout_name,
                                    label: rowSelect.destination_checkout_name,
                                    key: +rowSelect.destination_checkout_id!,
                                }: undefined}
                            >
                                <Select                           
                                    labelInValue  
                                    placeholder= 'Seleccione una caja'    
                                    style={{minWidth: '200px'}}                               
                                >
                                    {listDestinationCheckout && destinationBranch && listDestinationCheckout
                                        .filter((item) => item.branch.id === destinationBranch)
                                        .map((item) => (
                                            <Option value={item.name} key={item.id} >
                                                {item.name}
                                            </Option>
                                        ))
                                    } 
                                </Select>
                            </Form.Item> 
                        </Col>
                    
                    </Row>    
                    
                    <Form.Item
                        label='Monto'
                        name='amount'
                        rules={mandatory}
                        initialValue={rowSelect && (rowSelect.debit || rowSelect.credit) ? 
                            convertToNumber(rowSelect.debit || rowSelect.credit!) : undefined}
                    >
                        <InputNumber
                            placeholder="Monto"
                            addonBefore={cash?.currency.sign ? cash?.currency.sign : '$'}
                            onChange={onChangeAmount}
                        />
                    </Form.Item>  
                    <Form.Item
                        name='approved'
                        valuePropName="checked"
                    >
                        <Checkbox
                            checked={approved}
                            onChange={onChangeapproved}
                        >
                            Aprobado
                        </Checkbox>
                    </Form.Item>
                    <Form.Item 
                        label='Descripción'
                        name='description'
                        initialValue={rowSelect ? rowSelect.description : undefined}
                    >
                        <Input
                            placeholder="Comentario"
                        />
                    </Form.Item>   
                    <span style={{color: 'red'}}>{spanError && spanError} </span>             
                </Form>
            </Modal>
        </div>
    )
}

export default TransfersBetweenBoxes;