import React, { useEffect, useState } from "react";
import { useGetCurrency } from "../../../Services/useGetCurrency";
import { CashDeskProps } from "../../../Services/useGetCashDesk";
import { Checkbox, CheckboxProps, Col, Form, FormInstance, Row, Select } from "antd";
import { ResponseAccounts } from "../../../Services/useAccounts";
import { useCashDesk } from "../../../Services/useCashDesk";
import './styles.scss';
import { DataType } from "../../../Contexts/UserContext/MovementAcountContext";
import { useGetBranch } from "../../../Services/useGetBranch";

export const FormItemsSell = React.memo<{
    form: FormInstance<any>
    secondCurrency:{
        id: string,
        sign: string,
        name: string
    } | undefined, 
    setSecondCurrency: Function, 
    is_main: boolean,    
    rowSelect?: DataType
}>(({
    form,
    secondCurrency, 
    setSecondCurrency,
    is_main,
    rowSelect
}) =>{

    const { Option } = Select;
    const {data: currencies} = useGetCurrency()  
    const {getCashCurrency} = useCashDesk()
    const {data: branches } = useGetBranch()

    const [checkedExtraction, setCheckedExtraction] = useState(false);
    const [listCahs, setListCash] = useState<CashDeskProps[]>()
    const [spanError, setSpanError] = useState<string>()    
    const [branchSelected, setBranchSelected] = useState<number | undefined>()
    const [cashSelected, setCashSelected] = useState<number | null>(null);
    const [valueCurrency] = useState( is_main 
        ? {
            value: 'Thether', //'Dolar
            label: 'Thether', //Dolar
            key: '3', //2
            sign: 'USDT' //'USD
        }
        : rowSelect
            ? {
                value:  secondCurrency?.name || '', //'Dolar
                label: secondCurrency?.name || '', //Dolar
                key: secondCurrency?.id || '', //2
                sign: secondCurrency?.sign || '' //'USD
            }
            :undefined
    )
    const [valueDisabled] = useState( is_main
        ? true
        : (rowSelect?.deposit_operation_approved == 'true' || rowSelect?.extraction_operation_approved == 'true' ) ? true : false)


    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]    

    useEffect(() => {
        setSpanError(undefined)
        let extraction_cash = form.getFieldValue('extraction_cash');
        initialValuesForm()//Para selector de cajas
        if(extraction_cash && extraction_cash.key){
            setCheckedExtraction(true)
        }else{
            setCheckedExtraction(false) 
            setListCash(undefined)
        }
        if(rowSelect){
            if(rowSelect.origin_checkout_branch_id){
                setBranchSelected(+rowSelect.origin_checkout_branch_id)
            }
        }

    }, []);

    const initialValuesForm = async () => { 
        const currencyId = is_main ? 3 : secondCurrency?.id;
        const currencySign = is_main ? 'USDT' : secondCurrency?.sign;
    
        if (currencyId) {
            const data = await getCashCurrency(Number(currencyId));
            
            if (data) {
                let is_not_bank = data.filter((x)=> x.type.id !== 2)
                setListCash(is_not_bank);
                if (data.length === 0) {
                    setSpanError(`No existen cajas en ${currencySign}. Cree una en Menú -> Administración -> Cajas`);
                } else if (spanError) {
                    setSpanError(undefined);
                }
            }
        }
    };

    const onChangeExtraction: CheckboxProps['onChange'] =(e) => {
        setCheckedExtraction(e.target.checked);  
        form.setFieldValue('extraction_cash', undefined);                
    };   

    const onChangeCurrency = async(values: {key:string | undefined, label: string}, record: any)=>{   
        setSecondCurrency({
            id: values.key,
            sign: record?.sign ? record.sign : undefined,
            name: values.label 
        })
        form.setFields([
            {
                name: 'sell_currency',
                errors: [],
            },
        ]);    
        form.setFieldValue('extraction_cash', undefined); 
        const data = await getCashCurrency(Number(values.key)) 
        if(data){
            let is_not_bank = data.filter((x)=> x.type.id !== 2)
            setListCash(is_not_bank)
            if(data.length == 0){
                setSpanError(`No existen cajas en ${ record?.sign ? record.sign : ''}. Cree una en Menú -> Administración -> Cajas`)
            }else{
                if(spanError){
                    setSpanError(undefined)
                }
            }
        } 
    }
 
    return(
    <div className="section_sell">                      
        <Form.Item
            name='sell_currency'
            className="sell_and_buy_divide "
        > 
            <div className="section_sell_title">Moneda venta</div> 
            <Select                           
                labelInValue  
                defaultValue={valueCurrency}
                loading={!currencies }
                placeholder={'Moneda'}                       
                style={{minWidth: '105px'}}
                disabled={valueDisabled} 
                onChange={(values, record)=>{
                    onChangeCurrency(values, record)                                                         
                }}
            >
                {currencies && currencies && currencies.map((item) => (
                    item.id !== 3 &&
                    <Option value={item.name} key={item.id} sign={item.sign}>
                        {item.name}
                    </Option>
                ))} 
            </Select>
        </Form.Item>
        <Form.Item>
            <Checkbox
                checked={checkedExtraction}
                onChange={onChangeExtraction}
                disabled={rowSelect?.extraction_operation_approved == 'true' ? true : false}
            >
                Extración en caja
            </Checkbox>
        </Form.Item>          
        {
            checkedExtraction &&
                <Row style={{ display: 'flex', gap: '10px', width: '100%' }}>
                    <Col style={{ flex: 1 }}>
                        <Form.Item
                            label='Sucursal'
                            name='branch_cahs_extraction'
                            rules={mandatory}
                        >
                            <Select
                                style={{minWidth: 120}}
                                labelInValue  
                                onChange={(e)=>{
                                    setBranchSelected(+e.key)
                                    setCashSelected(null);
                                    form.setFieldValue('extraction_cash', undefined)
                                }}
                                placeholder= 'Sucursal'
                            >
                                {branches && branches.map((item) => (
                                    <Option value={item.name} key={item.id}>
                                        {item.name}
                                    </Option>
                                ))} 
                            </Select> 
                        </Form.Item>
                    </Col>
                    <Col style={{ flex: 1 }}>
                        <Form.Item
                            label='Caja extracción'
                            name='extraction_cash' 
                            rules={mandatory}
                        >
                            <Select
                                labelInValue
                                style={{minWidth: 120}}
                                placeholder= 'Caja'
                                onChange={(e)=>setCashSelected(+e.key)}
                                value={cashSelected ? { 
                                    key: cashSelected.toString(), 
                                    label: listCahs?.find((item)=>item.id == cashSelected)?.name, 
                                    value: listCahs?.find((item)=>item.id == cashSelected)?.name 
                                } : null}  // Mostrar el valor seleccionado
                                disabled={rowSelect?.deposit_operation_approved == 'true'  ? true : false}  
                            >
                                {listCahs && listCahs
                                    .filter((item) => item.branch.id === branchSelected)
                                    .map((item) => (
                                    <Option value={item.name} key={item.id}>
                                        {item.name}
                                    </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                
            } 
        
        {spanError && <span style={{color: 'red'}}>{spanError}</span>} 
    </div>) 
}

)