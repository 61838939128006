import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import {  Button, Col, Form, Row, Select, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { UsersSectionProps, ValuesForm } from './types';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';
import {
  TruckOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { CashDeskProps, useGetCashDesk } from '../../../Services/useGetCashDesk';
import { useCashDesk } from '../../../Services/useCashDesk';
import { BalancesContext } from '../../../Contexts/UserContext/BalancesContext';


export interface DataType {
  index: number,
  id: number,
  checkout: string,
  currency: string,
  balance: string,
  future_balance: string,
  description: string,
  branch_name: string, 
  type_name: string,
}

const BalanceSections: React.FC<UsersSectionProps> = () => {

  const {adjustedTableHeigthScroll} = useContext(StylesContext) 
  const {data: cashs} = useGetCashDesk()
  const {getAllCheckout, getCheckout} = useCashDesk()
  const { mutateBalance, setMutateBalance } = useContext(BalancesContext) 
  const { Option } = Select;

  const [form_checkout] = useForm()
  const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
  const [dataTable, setDataTable] = useState<DataType[]>([])

  const error = 'NO HAY CAJAS CREADAS PARA HACER UNA BÚSQUEDA. CREE UNA EN: ADMINISTRACIÓN => CAJAS.'

  useEffect(()=>{
    handleSearch(true)
  },[])
    
  useEffect(()=>{
    handleSearch(false)
    if(mutateBalance){     
      setMutateBalance(false)
    }
    
  },[mutateBalance])

  const rowData = (data: CashDeskProps[]) =>{
      let filterData: DataType[]= []
      let list: CashDeskProps[] = data 
    
      if(list && list){
        list.map((data, index)=>{            
          filterData.push({
            index: index,
            id: data.id,
            checkout: data.name,
            currency: data.currency ? data.currency.name : '', //+ ' / ' + data.currency.sign : '',
            balance: data.balance.toString(),
            future_balance: data.future_balance.toString(),
            description: data.description,
            branch_name: data.branch.name,
            type_name: data.type.name
          })
        })        
          
      }       
      setDataTable(filterData)
    }

    const filters = (type: 'branch'  ) => {
      let textAndValue: { text: string, value: string }[] = [];
      let uniqueValues = new Set();
  
      cashs?.forEach((item) => {
          const code =  item.branch.name 
  
          if (!uniqueValues.has(code)) {
              uniqueValues.add(code);
              textAndValue.push({
                  text: code,
                  value: code
              });
          }
      });
      return textAndValue;
    }
    
    const columns: ColumnsType<DataType> = [
        {
            title: 'Caja',
            dataIndex: 'checkout',
            key: '1',
            className:'cursor-pointer',
        },
        {
          title: 'Sucursarl',
          dataIndex: 'branch_name',
          key: '2',
          className:'cursor-pointer',
          filters: filters('branch'),
          onFilter: (value, record) => typeof value === 'string' && record.branch_name == value,
          filterSearch: true, 
        },
        {
          title: 'Moneda',
          dataIndex: 'currency',
          key: '3',
          className:'cursor-pointer',
        },
        {
            title: 'Tipo de caja',
            dataIndex: 'type_name',
            key: '4',
            className:'cursor-pointer',
        },
        {
          title: 'Descripción',
          dataIndex: 'description',
          key: '5',
          className:'cursor-pointer',
          ellipsis: true,           
        }, 
        {
            title: 'Saldo actual',
            dataIndex: 'balance',
            render:(text)=><div
              className='hoverable-text'
              style={{
                color: Number(text) < 0 ? 'red' : '#ADADAD'
              }}
            >{ new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(text)}</div>,  
            key: '6',
        },
        {
            title: 'Saldo Futuro',
            dataIndex: 'future_balance',
            render:(text, record)=><div
              className='hoverable-text'
              style={{
                color: Number(record.future_balance) < Number(record.balance) ? 'red' : Number(record.future_balance)> Number(record.balance) ? '#abfd2e' : '#ADADAD'
              }}
            >{new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(text)}</div>,
            key: '7',
            className:'cursor-pointer',
            ellipsis: true,           
        },       
    ];
        
    const handleSearch = async(loading: boolean) =>{
      if(loading){
        setLoadingTableData(true)
      }      
      let formData:{checkout: ValuesForm}  = form_checkout.getFieldsValue()
      if(formData){
        let checkout_id =  formData.checkout?.key  
        if(checkout_id){
          const data = await getCheckout(+checkout_id, setLoadingTableData)
          if(data){
            rowData([data])
          }
        }else{
          const data = await getAllCheckout(setLoadingTableData) 
          if(data){
            rowData(data)
          }
        }  
      }    
    } 


    
  
    

    return( 
        <>
          <div className='balance_section calance_section_container'>                                  
              <Row
                 className='global_title_section'  
              >
                <Col>
                  <h3> <TruckOutlined style={{fontSize: 20, marginRight: '10px', color: '#37bde0'}}/>SALDOS DE CAJAS</h3> 
                </Col>            
              </Row>
              <Row
                 className='global_button_box'    
                 style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}            
              >
                <Col>
                  <Form
                    layout='inline'
                    form={form_checkout}
                    id='form_checkout'
                    onFinish={handleSearch}
                  >
                    <Form.Item
                      name='checkout'
                    >
                        <Select
                          labelInValue  
                          loading={!cashs }
                          placeholder={!cashs ? 'Cargando cajas...' : 'Seleccione una caja'}      
                          style={{minWidth: '200px'}} 
                          allowClear
                          showSearch
                        >
                            {cashs && cashs.map((item) => (
                              <Option value={item.name} key={item.id} currency={item.currency}>
                                {item.name}
                              </Option>
                            ))} 
                        </Select>
                    </Form.Item>
                    <Form.Item>
                    <Button
                      type='primary'
                      className='movements_cash_section_btn'
                      htmlType='submit'
                    >
                      Buscar
                    </Button>
                  </Form.Item>
                  </Form>
                </Col>   
                {
                  cashs && cashs.length == 0 &&
                    <div style={{marginTop: '10px'}}>
                        <span style={{color: '#ff6161', display: 'flex', fontFamily: 'monospace'}}>
                          <WarningOutlined style={{marginRight: '10px'}}/>{error}
                        </span>
                    </div>                 
                }              
              </Row>
              <Row 
                style={{
                    display:'flex',
                    justifyContent: 'center',
                    width: '100%',
                    height: '80%',
                    padding: '0px 30px',                        
                    border: '1px solid   #645fca6c'
                }}
              >
                {
                  loadingTableData
                  ? <SpinerHorizontal/> 
                  : <Table 
                      style={{
                          width: '100%',
                          marginTop: '1%'
                      }}
                          columns={columns} 
                          dataSource={dataTable}  
                          scroll={{ y: adjustedTableHeigthScroll }}                                 
                          pagination={{
                              pageSize: 50,
                              showSizeChanger: false,                                      
                          }}
                      />    
                  } 
              </Row>             
          </div>
      
        </>
        
        
)};

export default BalanceSections;