import { Row, Tooltip } from "antd"
import React from "react"
import {
    CloseOutlined,
    UndoOutlined,    
  } from '@ant-design/icons';
import { DataType } from "../../Contexts/UserContext/MovementAcountContext";

export const RenderNumOperations = React.memo((value: {text: any, record: DataType})=>{
    return(
        <Row
            justify={
              value.record.deleted === 'true' || value.record.is_deleting_operation === 'true'
                ? 'space-between'
                : 'start'
            }
        >
            <span >{value.text}</span>            
            {value.record.deleted === 'true' && 
                <Tooltip
                    title='Eliminado'
                >
                    <CloseOutlined style={{color: 'red'}}/>
                </Tooltip>                
            }
             {value.record.is_deleting_operation === 'true' && 
                <Tooltip
                    title='Nota de crédito'
                >
                    <UndoOutlined style={{color: 'red'}}/>  
                </Tooltip>                
            }
        </Row>
        
    )
  })

export default RenderNumOperations
