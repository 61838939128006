import React, {  useContext, useEffect, useState } from "react";
import './styles.scss';
import { Checkbox, CheckboxProps,   Col,   Form, Input, Modal, Row, Select } from "antd";
import { DepositExtractionFormProps } from "./types";
import { useGetAllClients } from "../../../Services/useGetAllClients";
import { CashDeskProps, useGetCashDesk } from "../../../Services/useGetCashDesk";
import { useGetOperationType } from "../../../Services/useGetOperationType";
import { useOperations } from "../../../Services/useOperations";
import { VariablesContext } from "../../../Contexts/UserContext/VariablesContext";
import { useGetCheckoutBank } from "../../../Services/useGetCheckoutBank";
import { ResponseAccounts, useAccounts } from "../../../Services/useAccounts";
import { UserContext } from "../../../Contexts/UserContext/UsersContext";
import { MovementAccountContext } from "../../../Contexts/UserContext/MovementAcountContext";
import { OperationsContext } from "../../../Contexts/UserContext/OperationsContext";
import { useGetBranch } from "../../../Services/useGetBranch";

export interface FilterValues{
    value: string,
    label: string,
    key: string,
}

interface inputsForms{
    date: {$d:string}
    operation_type: FilterValues,
    client: FilterValues,
    cash: FilterValues,
    amount: number,
    currency: FilterValues
    commission: string,
    description: string
}

type OperationData = {
    description: string;
    type_id: number;
    customer_id: number;
    user_id: number;
    approved: boolean;
    commission?: number;     
    debit?: number;
    credit?: number;
    destination_checkout_id?: number,    
    origin_checkout_id?: number;
};

export const DepositExtractionForm: React.FC<DepositExtractionFormProps> =({
    form, 
    rowSelect,
    setRowSelect
})=>{
    
    const { data: clients } = useGetAllClients()
    const { data: cashs } = useGetCashDesk()
    const { userData } =useContext(UserContext)
    const { data: cashs_bank } = useGetCheckoutBank()
    const { data: operation_type } = useGetOperationType()
    const { collapsedFilsters, setCollapsedFilters } = useContext(VariablesContext)
    const { setShowForms, showForms } = useContext(VariablesContext)
    const { createOperation, editOperation } = useOperations()
    const { getAccountsCurrencyAndCustomer } = useAccounts()  
    const { Option } = Select;
    const {data: branches } = useGetBranch()

    const {
        setMutate,
        showingAccount, 
      } = useContext(MovementAccountContext)

    const {
        setMutate: mutateOperationsSections,     
    } = useContext(OperationsContext)

    const filtered = operation_type && operation_type.filter(item => [1, 2, 3, 4].includes(item.id));
    const [ loadingBtn, setLoadingBtn ] = useState(false)
    const [ isTransferer, setIsTransferer ] = useState(false)
    const [ aproved, setAproved ] = useState(false)
    const [ operationBank, setOperationBank ] = useState<boolean>(false)
    const [ operationType, setOperationType ] = useState<string | undefined>()
    const [ spanError, setSpanError ] = useState<string>()
    const [ client, setClient ] = useState<string | undefined>()
    const [ currency, setCurrency ] = useState<{id: number, sign: string} | undefined>()
    const [ balance, setBalance ] = useState<ResponseAccounts>()
    const [amountInput, setAmountInput] = useState<number>()

    const [branchSelected, setBranchSelected] = useState<number | undefined>()
    

    const handleCancel =()=>{
        setLoadingBtn(false)
        setShowForms(Object.fromEntries(Object.keys(showForms).map(key => [key, false])))// pone todos en false
        if(setRowSelect){
            setRowSelect(undefined)  
        }        
    }

    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]    

    useEffect(()=>{
        if(currency && currency.id && client ){            
            getBalance()
        }    
    },[currency, client])

    useEffect(()=>{
        if(amountInput){
            if(balance && (operationType == '2' || operationType == '4')){ //depósitos
                if( amountInput > balance.balance ){            
                    let value = new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balance.balance)
                    setSpanError('La extraccón supera el monto de cuenta: ' + currency?.sign + ' ' + value  )  
                }
            }else{
                if(spanError){
                    setSpanError(undefined)
                }
            }
        }        
    },[balance])

    useEffect(() => {
        if(rowSelect){
            let cash_data
            let amount_data
            let isTransferer_data
            
            if(rowSelect.operation_type_id == '3' || rowSelect.operation_type_id == '4'){
                setOperationBank(true)
            }
            switch(rowSelect.operation_type_id){
                case '1': // Depósito efectivo
                case '3': // Depósito bancario
                    let cash_destination = cashs.filter((cash)=> cash.id == +rowSelect.destination_checkout_id!)[0]

                    cash_data ={
                        label: rowSelect.destination_checkout_name,
                        value: rowSelect.destination_checkout_name,
                        key: rowSelect.destination_checkout_id,
                    }                    
                    amount_data = +rowSelect.credit_number!
                   
                    setCurrency({id: cash_destination.currency.id, sign: cash_destination.currency.sign})                    
                    setAmountInput(+rowSelect.credit_number!)
                    if(rowSelect.destination_checkout_branch_id){
                        setBranchSelected(+rowSelect.destination_checkout_branch_id)
                    }
                    break;
                case '2': // Extracción efectivo
                case '4':  // Extracción Bancaria
                    let cash_origin = cashs.filter((cash) => cash.id == +rowSelect.origin_checkout_id!)[0];
                    cash_data = {
                        label: rowSelect.origin_checkout_name,
                        value: rowSelect.origin_checkout_name,
                        key: rowSelect.origin_checkout_id,
                    };                    
                    amount_data = +rowSelect.debit_number!;
                   
                    setCurrency({ id: cash_origin.currency.id, sign: cash_origin.currency.sign });
                    setAmountInput(+rowSelect.debit_number!);
                    if(rowSelect.origin_checkout_branch_id){
                        setBranchSelected(+rowSelect.origin_checkout_branch_id)
                    }
                    
                    break;
            }
            switch(rowSelect.operation_type_id){
                case '3':
                case '4':
                    setIsTransferer(true)
                    break;
                case '1':
                case '2':
                    setIsTransferer(false)
                    //isTransferer_data = rowSelect.com 
            }   
            
            setClient(rowSelect.customer_id)
            if(cash_data ){
                form.setFieldsValue({
                    operation_type: {
                        label: rowSelect.operation_type_name,
                        value: rowSelect.operation_type_name,
                        key: rowSelect.operation_type_id
                    },
                    client: {
                        label: rowSelect.customer_first_name + ' ' + rowSelect.customer_last_name,
                        value: rowSelect.customer_first_name + ' ' + rowSelect.customer_last_name,
                        key: rowSelect.customer_id
                    },
                    cash: cash_data,
                    amount: amount_data,
                    description: rowSelect.description
                    //commission
                });  
            }            
        }else{
            form.resetFields(undefined) 
        }
        
    }, []);

    const getBalance = async() =>{  
        const data = await getAccountsCurrencyAndCustomer(Number(client), Number(currency?.id))
        if(data){
            setBalance(data)

        }              
    }

    const handleSave = async (e: inputsForms)=>{
        setLoadingBtn(true)
        let operationData: OperationData | undefined;  
        if(userData){
            switch (e.operation_type.key) {
                case '1': // Depósito efectivo
                case '3': // Depósito bancario
                    operationData = {                                     
                        description: e.description,
                        type_id: Number(e.operation_type.key),
                        destination_checkout_id: Number(e.cash.key),
                        customer_id: Number(e.client.key),
                        user_id: userData?.id,
                        credit: Number(e.amount),  
                        approved: aproved                                                                             
                    };
                    if(e.operation_type.key == '3'){
                        operationData.commission = Number(e.commission);
                    }
                    break;
                case '2': // Extracción efectivo
                case '4': // Extracción bancaria
                    operationData = {
                        description: e.description,
                        type_id: Number(e.operation_type.key),                
                        origin_checkout_id: Number(e.cash.key),
                        customer_id: Number(e.client.key),
                        user_id: userData?.id,
                        debit: Number(e.amount),   
                        approved: aproved,                    
                    };
                    if(e.operation_type.key == '4'){
                        operationData.commission = Number(e.commission);
                    }
                    break;
                default:
                    break;
            }
        }             
        if(operationData){
            let newData
            if(rowSelect){
                newData = await editOperation(handleCancel, rowSelect.id!, operationData)
            }else{
                newData = await createOperation(handleCancel, operationData)
            }  
            // if(newData){
            //     if (
            //         showingAccount && 
            //         showingAccount.user_id == newData.customer.id 
            //     ){
            //         setMutate(true)
            //     }
            //     mutateOperationsSections(true)
            // }         
            
            
            //checkAccountBalance()
            setCollapsedFilters(!collapsedFilsters)            
        }                
    }    

    const changeTransferer = (e: FilterValues ) =>{
        if(e.key == '3' || e.key == '4'){
            setOperationBank(true)
        }else{
            setOperationBank(false)
        }
        setSpanError(undefined)
        setOperationType(e.key)
        form.setFieldsValue({
            cash: undefined,
        });
        if(e.key == '3' || e.key == '4'){//is transferer
            setIsTransferer(true)
        }else{
            setIsTransferer(false)
        }         
    }

    const onChangeClient = async(e: FilterValues) =>{
        setClient(e.key)
        
    }

    const onChangeCash = async (e: FilterValues, values: any) =>{
        if(spanError){
            setSpanError(undefined)
        }
        if(values?.currency){
            setCurrency({id: values.currency, sign: values.sign})            
        }        
    }
 
    const onChangeAproved: CheckboxProps['onChange'] = async (e) => {
        setAproved(e.target.checked); 
    };

    const onChangeAmount = (e: any) =>{
        setAmountInput(e.target.value)
        if(balance && (operationType == '2' || operationType == '4')){ //depósitos
            if(e.target.value > balance.balance ){            
                let value = new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balance.balance)
                setSpanError('La extraccón supera el monto de cuenta: ' + currency?.sign + ' ' + value  )  
            }
        }
    }            

    return(
        <div>
            <Modal
                title= 'Depósito / Extracción'
                open={showForms.deposit_extraction }
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form.submit()}
            >
                <Form
                    form={form}
                    id='form_deposit_extraction'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >                   
                    <Form.Item
                        label='Depósito / Extracción'
                        name='operation_type'                
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Select
                            labelInValue
                            placeholder="Seleccione el tipo de movimiento"   
                            onChange={changeTransferer}                         
                        >
                            {filtered && filtered.map((item) => (
                                <Option value={item.name} key={item.id} >
                                    {item.name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>                       
                    <Form.Item
                        label='Cliente'
                        name='client'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Select                           
                            labelInValue  
                            loading={!clients }
                            placeholder={!clients ? 'Cargando campos...' : 'Cliente'}      
                            style={{minWidth: '200px'}} 
                            showSearch   
                            onChange={onChangeClient}                                 
                        >
                            {clients && clients.map((item) => (
                                <Option value={item.first_name + ' ' + item.last_name} key={item.id} >
                                    {item.first_name + ' ' + item.last_name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item> 
                    
                    <Row style={{ display: 'flex', gap: '10px', width: '100%' }}>
                        <Col style={{ flex: 1 }}>
                            <Form.Item
                                label='Sucursal'
                                name='branch_cahs_extraction'
                                rules={mandatory}
                            >
                                <Select
                                    style={{minWidth: 120}}
                                    labelInValue  
                                    onChange={(e)=>{
                                        setBranchSelected(+e.key)
                                        form.setFieldValue('extraction_cash', undefined)
                                    }}
                                    placeholder= 'Sucursal'
                                >
                                    {branches && branches.map((item) => (
                                        <Option value={item.name} key={item.id}>
                                            {item.name}
                                        </Option>
                                    ))} 
                                </Select> 
                            </Form.Item>
                        </Col>
                        <Col style={{ flex: 1 }}>
                            <Form.Item
                                label='Caja'
                                name='cash'
                                rules={[
                                    {
                                        required: true,
                                        message:'Este campo es obligatorio'
                                    }
                                ]}                                
                            >
                            {
                                operationBank ?
                                <Select                           
                                    labelInValue  
                                    loading={!cashs_bank }
                                    placeholder={!cashs_bank ? 'Cargando cajas...' : 'Seleccione una caja'}      
                                    style={{minWidth: '200px'}}  
                                    showSearch   
                                    onChange={onChangeCash}                          
                                >
                                    {cashs_bank && cashs_bank
                                        .filter((item) => item.branch.id === branchSelected)
                                        .map((item) => (
                                        <Option value={item.name} key={item.id} currency={item.currency.id} sign={item.currency.sign}>
                                            {item.name}
                                        </Option>
                                    ))} 
                                </Select> :
                                <Select                           
                                    labelInValue  
                                    loading={!cashs }
                                    placeholder={!cashs ? 'Cargando cajas...' : 'Seleccione una caja'}      
                                    style={{minWidth: '200px'}}  
                                    showSearch    
                                    onChange={onChangeCash}                          
                                >
                                    {cashs && cashs
                                        .filter((item) => item.branch.id === branchSelected)
                                        .filter((item) => item.type.id !== 2 )
                                        .map((item) => (
                                        <Option value={item.name} key={item.id}  currency={item.currency.id} sign={item.currency.sign}>
                                            {item.name}
                                        </Option>
                                    ))} 
                                </Select>                                
                            }                       
                        </Form.Item>  
                        </Col>
                    </Row>
                 
                                         
                    <Form.Item
                        label='Monto'
                        name='amount'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Input
                            placeholder="Monto"
                            type="number"
                            onChange={onChangeAmount}
                        />
                    </Form.Item>
                    <Form.Item
                        name='aproved'
                    >
                        <Checkbox
                            checked={aproved}
                            onChange={onChangeAproved}
                        >
                            Aprobado
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        label='Descripción'
                        name='description'
                    >
                        <Input
                            placeholder="Comentario"
                        />
                    </Form.Item>
                    {
                        isTransferer &&
                        <Form.Item
                            label='Comisión %'
                            name='commission'
                            initialValue={3}
                            rules={[
                                {
                                    required: true,
                                    message:'Este campo es obligatorio'
                                }
                            ]}
                        >
                            <Input
                                placeholder="Comisión %"
                                type="number"
                            />
                        </Form.Item>
                    }     
                    <span style={{color: 'red'}}>{spanError && spanError} </span>          
                </Form>
            </Modal>
        </div>
    )
}

export default DepositExtractionForm;
