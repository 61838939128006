import { Button, Collapse, CollapseProps, Row } from 'antd'
import './styles.scss'
import { MovementsBoxProps } from './types'
import { ShowFormsProps, VariablesContext } from '../../../Contexts/UserContext/VariablesContext'
import { useContext } from 'react'

export const MenuBox: React.FC<MovementsBoxProps> = ({
    
}) =>{

    const { setShowForms, showForms } = useContext(VariablesContext)

    const openForm = (keyToSetTrue: keyof typeof showForms)=>{
        setShowForms((prev: ShowFormsProps ) => 
            Object.keys(prev).reduce((acc, key) => {
                acc[key as keyof typeof showForms] = key === keyToSetTrue ? true : false;
                return acc;
            }, {} as typeof showForms)
        );
    }

    const btnsData_1 = [
        {
            label: 'Cliente',
            onClick: ()=>{
                openForm('buy_and_sell')
            }
        },
        {
            label: 'Ventanilla',
            onClick: ()=>{
                openForm('windows')
          
            }
        },
        {
            label: 'USDT',
            onClick: ()=>{                
                openForm('usdt')
            }
        },
   
    ]

    const btnsData_2 = [       
        {
            label: 'Depósito / Extracción',
            onClick: ()=>{
                openForm('deposit_extraction')
            }
        }, 
        {
            label: 'Cliente - Proveedor', 
            onClick: ()=>{
                openForm('transferer_to_suppliers')
            }
        }, 
    ]

    const btnsData_3 = [    
         
        {
            label: 'Entre cuentas clientes',
            onClick: ()=>{
                openForm('transferer')
            }
        },

        {
            label: 'Entre cajas',
            onClick: ()=>{
                openForm('transfers_between_cashs')
            }
        }
    ]
  
    const items: CollapseProps['items'] = [
        {
          key: '1',
          label: 'Compra / venta',
          children:  btnsData_1.map((btn)=>
            <Button
                type='primary'
                className='movements_section_btn'
                style={{
                    marginBottom: '10px',
                    width: '85%'
                }}
                onClick={btn.onClick}
            >
                {btn.label}
            </Button>
        )   ,
        },
        {
          key: '2',
          label: 'Depósitos - Extracción',
          children: btnsData_2.map((btn)=>
            <Button
                type='primary'
                className='movements_section_btn'
                style={{
                    marginBottom: '10px',
                    width: '85%'
                }}
                onClick={btn.onClick}
            >
                {btn.label}
            </Button>
        )  ,
        },
        {
          key: '3',
          label: 'Movimientos',
          children: btnsData_3.map((btn)=>
            <Button
                type='primary'
                className='movements_section_btn'
                style={{
                    marginBottom: '10px',
                    width: '85%'
                }}
                onClick={btn.onClick}
            >
                {btn.label}
            </Button>
        )  ,
        },
      ];



    const onChange = (key: string | string[]) => {
        console.log(key);
      };

    return(
        <div className='movements-box'>
            <p className='movements-box_title'>FORMULARIOS</p>
            <Collapse 
                style={{color:'white'}}
                items={items} defaultActiveKey={[]} onChange={onChange} />
            <div 
                className='movements-box_container'  
                style={{
                    marginTop:'50px'
                }}          
            >  
           
                                        
            </div>   
        </div>
         
    )
}