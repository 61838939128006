import React from 'react';
import { Tooltip } from 'antd';
import { DataType_AllOperation } from '../Sections/AllCheckouts/types';
import {
    MinusCircleOutlined, 
    SwapOutlined, 
    TeamOutlined, 
    PlusCircleOutlined
  } from '@ant-design/icons';
import { DataType } from '../../Contexts/UserContext/MovementAcountContext';

const getDescriptionSuffix = (
    associated_operation: string | undefined, 
    description: string | undefined, 
    is_deleting_operation: string | undefined, 
    is_sale: string | undefined,
    usdt_sale: string | undefined
) => {
    if(associated_operation && description && is_deleting_operation == 'false'){
        let txt = ''
        if(is_sale){               
            switch(is_sale){
                case 'true':
                    txt = '(por venta)'
                    break;
                case 'false':
                    txt = '(por compra)'
                    break;
                default:
                    txt = 'usdt'
            }
        }
        if(usdt_sale){
            switch(usdt_sale){
                case 'true':
                    txt = '(x venta USDT)'
                    break;
                case 'false':
                    txt = '(x compra USDT)'
                    break;
                default:
                    txt = 'usdt'
            }
        }
        return txt
    }else{
        return ''
    }    
};

export const RenderOperationType = React.memo((value: { text: any; record: DataType_AllOperation | DataType }) => {
    let txt: React.ReactNode = ''; // Cambiado a React.ReactNode para admitir JSX
    let colorTxt =  'white';
    let is_buy_sale = ''
    let colorDetail = '#ff00c0'
    let sale: undefined | string = undefined

    switch (value.record.operation_type_id) {
        case '1':
            if(value.record.is_deleting_operation == 'false') { colorTxt = '#37bde0'}            
           
            txt = 'Depósito'; // Cuenta corriente           
            is_buy_sale = getDescriptionSuffix(
                value.record.associated_operation, 
                value.record.description, 
                value.record.is_deleting_operation, 
                value.record.is_sale,
                value.record.usdt_sale
            )
            
        break;
        case '2':
            if(value.record.is_deleting_operation == 'false') { colorTxt = '#37bde0'}
            txt =  'Extracción'; // Cuenta corriente
            is_buy_sale = getDescriptionSuffix(
                value.record.associated_operation, 
                value.record.description, 
                value.record.is_deleting_operation,
                value.record.is_sale,
                value.record.usdt_sale
            )
            break;
        case '3':
            if(value.record.is_deleting_operation == 'false') { colorTxt = '#00fff9'}
            txt = (<>Transferencia <PlusCircleOutlined /> {/* Depósito bancario */} </>);
            break;
        case '4':
            if(value.record.is_deleting_operation == 'false') { colorTxt = '#00fff9'}
            txt = (<>Transferencia <MinusCircleOutlined /> {/* Extracción bancaria */}</>);
            break;
        case '5':
            if(value.record.is_deleting_operation == 'false') { colorTxt = '#37bde0'}
            txt = (<>Movimiento <SwapOutlined /> {/* Transferencia entre cajas */}</>);
            break;
        case '6':
            if(value.record.is_deleting_operation == 'false') { colorTxt = '#dfdf16'}
            txt = (<>Movimiento <TeamOutlined /> {/* Transferencia entre cuentas */}</>);
            break;
        case '7':
            let add = ''
            if(value.record.is_deleting_operation == 'false') { colorTxt = '#ff00c0'}
            if(value.record.usdt_sale){
                add = '(x venta USDT)'
            }
            txt = value.record.is_sale === 'true' ? 'Venta ' + add : 'Compra ' + add;
            break;
        case '8':
            if(value.record.is_deleting_operation == 'false') { colorTxt = '#655fca'}
            txt = 'Comisión';
            break;
        case '9': //compra / venta de USDT
            if(value.record.is_deleting_operation == 'false') { colorTxt = 'gray'}
            if(value.record.usdt_sale === 'true') {txt =  'Venta USDT' }
            if(value.record.usdt_sale === 'false') {txt = 'Compra USDT'}
            colorTxt = '#ff00c0';
            break;
        case '10':
            if(value.record.is_deleting_operation == 'false') { colorTxt = 'yellow'}
            txt = (<>T. Proveedor <PlusCircleOutlined /> {/* Depósito por transferencia a proveedor */}</>);
            break;
        case '11':
            if(value.record.is_deleting_operation == 'false') { colorTxt = 'yellow'}
            txt = (<>T. Proveedor <MinusCircleOutlined /> {/* Extracción por transferencia a proveedor*/}</>);
            colorTxt = 'yellow';
            break;
        case '12':
            if(value.record.is_deleting_operation == 'false') { colorTxt = 'gray'}
            txt = 'Comisión';
            break;
        default:
            txt = '';
            break;
    }
    if(value.record.deleted == 'true' || value.record.is_deleting_operation == 'true'){
        colorTxt =  'rgb(114, 114, 114)'
        colorDetail = 'rgb(114, 114, 114)'
    }
    return <Tooltip title={value.record.operation_type_name}>
            <span style={{ color: colorTxt, fontWeight: 700 }}>{txt}</span>
            {is_buy_sale && <span style={{ color: colorDetail, fontWeight: 700 }}> {is_buy_sale}</span>}
        </Tooltip> // Renderiza el contenido con el color correspondiente
});

export default RenderOperationType