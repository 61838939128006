import { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_OPERATION_GET_ALL } from '../Axios/ENDPOINTS';
import { BranchProps } from "./useGetBranch";

interface Currency {
  id: number;
  name: string;
  sign: string;
}

export interface Customer {
  id: number;
  created_at: string;
  updated_at: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}

interface AccountType {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
}

interface Account {
  id: number;
  created_at: string;
  updated_at: string;
  description: string;
  balance: number;
  currency: Currency;
  customer: Customer;
  type: AccountType;
}

interface Checkout {
  id: number;
  created_at: string;
  updated_at: string;
  description: string;
  branch: BranchProps;
  currency: Currency;
  balance: number;
  name: string;
  future_balance: number
}

interface OperationType {
  id: number;
  name: string;
}

interface User {
  id: number;
  created_at: string;
  updated_at: string;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
}

export interface Transaction {
  approved: boolean;
  approved_date: string | null;
  can_be_approved: boolean | null;
  commission: number | null;
  created_at: string;
  credit: number;
  currency_quote_buy_price: number;
  currency_quote_sell_price: number;
  currency_sale: boolean | null;
  customer: Customer;
  debit: number;
  deleted: boolean;
  deposit_operation_approved: boolean;
  description: string;
  destination_account: Account;
  destination_account_balance: number;
  destination_checkout: Checkout;
  destination_checkout_balance: number;
  extraction_operation_approved: boolean;
  full_name: string;
  id: number;
  is_deleting_operation: boolean;
  operation_id: number;
  origin_account: Account;
  origin_account_balance: number;
  origin_checkout: Checkout;
  origin_checkout_balance: number;
  related_operation_id: number | null;
  related_operation_user_id: number | null;
  secondary_currency: Currency;
  supplier_commission: number | null;
  type: OperationType;
  updated_at: string;
  usdt_quote_percentage: number | undefined;
  usdt_sale: boolean | null;
  user: User;
  windows_operation: boolean
  
}

export interface ResponseGetUsers{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: Transaction[]  
}

export const useGetMovements = () => {   

    const { data, error, mutate }:{
      data: Transaction[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([END_POINT_OPERATION_GET_ALL])    
  
    return {
      data,
      mutate,
      error
    };
};