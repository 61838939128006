import React, {
    createContext,
    useEffect,
    useState,
  } from 'react';
import { CheckoutBalance } from '../../Services/useCashDesk';
import { DataType } from './MovementAcountContext';
import { DataType_AllOperation } from '../../Components/Sections/AllCheckouts/types';
import Table, { ColumnsType } from "antd/es/table"
import { BranchProps } from '../../Services/useGetBranch';
import { Transaction } from '../../Services/useGetMovements';

  class VoidContext implements OperationsContext {
    get dataTable(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setDataTable(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get mutate(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setMutate(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showingCash(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowingCash(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get balance(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setBalance(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get sectionsFilters(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setSectionsFilters(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setDataTableAllCashes(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get dataTableAllCashes(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get columns(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setColumns(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get branchSelected(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setBranchSelected(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get dataApi(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setDataApi(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get columnsOperationSections(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setColumnsOperationSections(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get deletedKey (): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setDeletedkey(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get approvedKey (): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setApprovedkey(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get rowSelect (): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setRowSelect(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showConfirmDelete(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowConfirmDelete(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get keyNumberDeleted(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setKeyNumberDeleted(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showConfirmDeleteAllCheckout(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowConfirmDeleteAllCheckout(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get rowSelect_allCheckout(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setRowSelect_allCheckout(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get mutateAllCheckout(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setMutateAllCheckout(): never {
      throw new Error('Cannot consume context outside of provider');
    }
  }

  interface OperationsContext {
    dataTable: DataType[], 
    setDataTable: Function,
    mutate: boolean 
    setMutate: Function,
    showingCash:{
      name: string,
      id: number
    } | undefined, 
    balance: CheckoutBalance | undefined,
    setBalance: Function
    setShowingCash: Function,
    sectionsFilters: OperationsSectionsFilters | undefined,
    setSectionsFilters: Function,
    dataTableAllCashes: DataType_AllOperation[],
    setDataTableAllCashes: Function,
    columns: ColumnsType<DataType_AllOperation>,
    setColumns: Function,
    branchSelected: BranchProps | undefined,
    setBranchSelected: Function,
    dataApi: Transaction[] | undefined,
    setDataApi: Function,
    columnsOperationSections: ColumnsType<DataType> | undefined
    setColumnsOperationSections: Function,
    deletedKey: string | undefined,
    setDeletedkey: Function,
    approvedKey: string | undefined,
    setApprovedkey: Function,
    rowSelect: DataType | undefined,
    setRowSelect: Function,
    showConfirmDelete: boolean,
    setShowConfirmDelete: Function,
    keyNumberDeleted: number, 
    setKeyNumberDeleted: Function,
    showConfirmDeleteAllCheckout: boolean
    setShowConfirmDeleteAllCheckout: Function,
    rowSelect_allCheckout: DataType | undefined,
    setRowSelect_allCheckout: Function,
    mutateAllCheckout: boolean,
    setMutateAllCheckout: Function
    
    
  }

  export interface ValuesForm{
    value: string,
    label: string,
    key: number
}

  interface OperationsSectionsFilters2 {
    date: {
      to: string | undefined,
      from: string | undefined
    },
    operation_type:{
      id: number | undefined,
      name: string | undefined
    },
    customer:{
      id: number | undefined,
      name: string | undefined
    },
    currency: {
      id: number | undefined,
      name: string | undefined,
      sign: string | undefined
    }
  }

  interface OperationsSectionsFilters {
    date: {
      to: string | undefined,
      from: string | undefined
    },
    operation_type: ValuesForm | undefined,
    customer: ValuesForm | undefined,
    currency: ValuesForm | undefined,
    branch: ValuesForm | undefined,
    chekout: ValuesForm | undefined,
  }

  interface propsWithChildren {
    children: React.ReactNode
  }
  
  export const OperationsContext = createContext<OperationsContext>(new VoidContext());
  export const OperationsContextProvider= ({ children }: propsWithChildren): JSX.Element => {

    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [dataTableAllCashes, setDataTableAllCashes] = useState<DataType_AllOperation[]>([])
    const [mutate, setMutate] = useState<boolean>(false)

    //VARIABLES DE OPERATIONS SECTIONS
    const [balance, setBalance] = useState<CheckoutBalance | undefined>()
    const [dataApi, setDataApi] = useState<Transaction[]>([]) //Operationsections
    const [columnsOperationSections, setColumnsOperationSections] = useState<ColumnsType<DataType>>();
    const [deletedKey, setDeletedkey] = useState<string>('')
    const [approvedKey, setApprovedkey] = useState<string>('') 
    const [rowSelect, setRowSelect] =useState<DataType | undefined>() 
    const [showConfirmDelete, setShowConfirmDelete] = useState(false) 

    //Variables de allCheckout
    const [sectionsFilters, setSectionsFilters] = useState<OperationsSectionsFilters>()
    const [columns, setColumns] = useState<ColumnsType<DataType_AllOperation>>([])  
    const [branchSelected, setBranchSelected] = useState<BranchProps>()  
    const [keyNumberDeleted, setKeyNumberDeleted]= useState<number>(0)
    const [showConfirmDeleteAllCheckout, setShowConfirmDeleteAllCheckout] = useState(false)
    const [rowSelect_allCheckout, setRowSelect_allCheckout] =useState<DataType | undefined>() 
    const [mutateAllCheckout, setMutateAllCheckout] = useState<boolean>(false)    

    const [ showingCash, setShowingCash ] = useState<{
      name: string,
      id: number,
    } | undefined>()

    return (
      <OperationsContext.Provider value={{
        dataTable, 
        setDataTable,
        mutate, 
        setMutate,
        showingCash, 
        setShowingCash,
        balance, 
        setBalance,
        sectionsFilters, 
        setSectionsFilters,
        dataTableAllCashes, 
        setDataTableAllCashes,
        columns,
        setColumns,
        branchSelected, 
        setBranchSelected,
        dataApi,
        setDataApi,
        columnsOperationSections, 
        setColumnsOperationSections,
        deletedKey, 
        setDeletedkey,
        approvedKey, 
        setApprovedkey,
        rowSelect, 
        setRowSelect,
        showConfirmDelete,
        setShowConfirmDelete,
        keyNumberDeleted, 
        setKeyNumberDeleted,
        showConfirmDeleteAllCheckout, 
        setShowConfirmDeleteAllCheckout,
        rowSelect_allCheckout, 
        setRowSelect_allCheckout,
        mutateAllCheckout, 
        setMutateAllCheckout
      }}>{children}</OperationsContext.Provider>
    );
  };



  