import { axiosBackEndInstance } from '../Axios';
import { notification, message } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { CurrencyProps, useGetCurrency } from './useGetCurrency';
import { END_POINT_USER_CHECKOUT } from '../Axios/ENDPOINTS';
import { User } from './useUsers';
import { CashDeskProps } from './useGetCashDesk';

export interface OperationsCashProps {
  id: number,
  user: User,
  user_id: number,
  checkout: CashDeskProps,
  checkout_id: number
}

export interface ResponseGetUsers{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: OperationsCashProps[]  
}


export const useGetOperationCash = () => {

    const {errorHandling} = useErrorHandling()

    const getOperationCash = async(
      checkout_id: number,     
    ): Promise<OperationsCashProps[] | undefined> =>{
      try{
        const  data = await axiosBackEndInstance.get(
           `${END_POINT_USER_CHECKOUT}/get_all/${checkout_id}`
        )
        const status =  errorHandling(data).request.status
        if(status === 200){
          return data.data        
        }
      }catch(newError: any){
        if( newError && newError.response && newError.response.data && newError.response.data.detail && newError.response.data.detail == 'Currency quote not found'){
          notification.info({
            message: 'Info', 
            description:'No hay un tipo de cambio creado para esa moneda. Cree uno en Menú => Administración => Tipo de cambio', 
            placement: 'topRight'
          })
        }
        console.log(newError);
      }
    }
     
    const createOperationCash = async (
      values:{        
        user_id: any,
        checkout_id: number,
      }
      
      ): Promise<any> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_USER_CHECKOUT, values
      )
      const status =  errorHandling(data).request.status
      if(status === 200){
        message.open({
          type: 'success',
          content: 'Operador asignado',
        });
      }
    } catch (newError) {
      console.log(newError);
    }
  };

  const editOperationCash = async (
    callback: Function,
    id: number,
    values:{
      name: string,
      sign: string
    }
                            
  ): Promise<void> => {
              
    try {
      const data  = await axiosBackEndInstance.put(
        `${END_POINT_USER_CHECKOUT}${id}`, values
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        notification.success({
          message: 'Éxito', 
          description:'Moenda editada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {    
      callback()
      let status = errorHandling(newError).request.status
      console.log('Status ' + status);
      console.log(newError);
    }
  };

  const deleteOperationCash = async (
    id: number                            
  ): Promise<string | undefined> => {
      
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_USER_CHECKOUT}/${id}`, 
      );
      if(data.status === 200){
        message.open({
          type: 'success',
          content: 'Eliminado con exito',
        });
        return 'deleted'
      }    
    } catch (newError) {
      console.log(newError);
      let status = errorHandling(newError).request.status
    }
  };

  return {
    getOperationCash,
    createOperationCash,
    editOperationCash,
    deleteOperationCash
  };
};
